import { Box, Grid, Switch } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Header } from '@/components/shared/Header/Header';
import { AddOns } from '@/components/shared/AddOns';
import { ContentLayout } from '@/components/shared//Layout/ContentLayout';
import { Summary } from '@/components/shared//Summary';
import { Eur } from '@/constants/currencies';
import { Footer } from '@/components/shared/Footer';
import { Perks } from '@/components/shared/Perks';
import { AmountField } from '@/components/shared/fields/AmountField';
import { Segment } from '@/components/shared/Segment';
import { Sticky } from '@/components/shared/Sticky';
import { CurrencyProvider } from '@/contexts/CurrencyContext';
import { usePreferences } from '@/contexts/PreferencesContext';
import { OfferProvider } from '@/contexts/OfferContext';
import { hasProp } from '@/utils/has-prop';

import { useHrParams } from '../Croatia/hooks/useHrParams';
import { Options } from '../Croatia/components/Options';
import { StepHr } from '../Croatia/enums/StepHr';
import { optimisations } from '../Croatia/constants/optimisations';
import { amountFields, fieldsPerDetails } from '../Croatia/constants/fields';
import { chart } from '../Croatia/constants/chart';
import { MIN_BRUTO_1 } from '../Croatia/constants/constants';
import { OtpLeasingInfo } from './OtpLeasingInfo';
import { PorscheLeasingInfo } from './PorscheLeasingInfo';
import { SectionHeader } from '../../shared/SectionHeader';
import { SelectField } from '../../shared/fields/SelectField';
import { useBrand } from '../../../hooks/useBrand';
import { Forbidden } from '../../shared/Forbidden';
import { OtpLeasing } from './OtpLeasing';
import { EntryRow } from '../../shared/EntryRow';
import { useState } from 'react';
import { TYear } from '../Croatia/interfaces/TYear';
import { useCountyTaxRateSelect } from '../Croatia/Croatia.utils';
import { COUNTIES } from '../Croatia/constants/calculator';
import { LeasingType } from '../Croatia/Leasing.utils';

const counties2023 = COUNTIES[2023];

export const CroatiaCarsInfo = ({ legacy = false }: { legacy?: boolean }) => {
  const [year, setYear] = useState<TYear>(new Date().getFullYear() === 2024 ? 2024 : 2025);

  const brand = useBrand();
  const { urlParams, offerValue, state, setField } = useHrParams(brand.brand, false, false, { legacy, year });
  const { t } = useTranslation();
  const {
    preferences: { details },
  } = usePreferences();

  const stope = useCountyTaxRateSelect(year);

  if (!brand.config.cars) {
    return <Forbidden inline />;
  }

  const filteredAmountFields = amountFields
    .filter(({ property }) => fieldsPerDetails[details].indexOf(property) !== -1)
    .filter(({ property }) => (hasProp(state.calculated, property) ? state.calculated[property] : undefined));

  return (
    <CurrencyProvider local={Eur} primary={Eur}>
      <OfferProvider value={offerValue}>
        <Header isInternal={false} isOffer={false} urlParams={urlParams} state={state} chart={chart} />
        {stope.find(({ value }) => value === state.values.stopa) ? null : (
          <Box bg="red" color="white" px={8} py={2} textAlign="center">
            {counties2023.find((item) => item?.id === state.values.stopa)?.name} still didn&apos;t define their tax rate
            for 2024.
            <br />
            The deadline is 31st of December 2023
            <br />
            <br />
            <b>The calculation is not correct!</b>
          </Box>
        )}
        <ContentLayout>
          <Sticky mb={8}>
            <AmountField
              title={t('common.totalPay')}
              description={t('hr.totalPayInfo') as string}
              hint={state.defaultState ? (t('common.totalPayHint') as string) : undefined}
              value={state.values.total}
              onChange={(value) => setField('total', value)}
              isInvalid={state.calculated.bruto1 < MIN_BRUTO_1}
              name="total"
            />
          </Sticky>

          <EntryRow title="2025 tax laws">
            <Box textAlign="right">
              <Switch
                isChecked={year === 2025}
                onChange={(e) => setYear(e.target.checked ? 2025 : 2024)}
                colorScheme="brand"
              />
            </Box>
          </EntryRow>

          <AddOns state={state} setField={setField} title={t(StepHr.NeoporeziviDodaci)} items={optimisations} />
          <Segment>
            <>
              <SectionHeader title={t('cars.section')} />
              <SelectField
                title={t('cars.carLeasingProvider')}
                value={state.values.carLeasingProvider}
                options={[
                  { value: 0, name: t('cars.noCarLeasing') },
                  { value: LeasingType.OTPInfo, name: 'OTP/Flota - info' },
                  { value: LeasingType.Porsche, name: 'Porsche Leasing - info' },
                  { value: LeasingType.OTP, name: 'OTP/Flota' },
                ]}
                onChange={(value: number) => setField('carLeasingProvider', value)}
                name="carLeasingProvider"
              />
              {state.values.carLeasingProvider === LeasingType.OTPInfo && (
                <OtpLeasingInfo
                  state={state}
                  setField={setField}
                  companyCostLabel={`cars.companyCosts.${brand.brand}`}
                />
              )}
              {state.values.carLeasingProvider === LeasingType.Porsche && (
                <PorscheLeasingInfo
                  state={state}
                  setField={setField}
                  companyCostLabel={`cars.companyCosts.${brand.brand}`}
                />
              )}
              {state.values.carLeasingProvider === LeasingType.OTP && (
                <OtpLeasing
                  state={state}
                  setField={setField}
                  companyCostLabel={`cars.companyCosts.${brand.brand}`}
                  legacy={legacy}
                />
              )}
            </>
          </Segment>
          <Perks state={state} setField={setField} country="hr" />
          <Options state={state} setField={setField} isOffer={false} year={year} />

          <Segment>
            <EntryRow title="">
              {details === 2 ? (
                <Grid templateColumns={'1fr 1fr'}>
                  <Box textAlign="right" pr={2}>
                    {t('cars.withoutCar')}
                  </Box>
                  <Box textAlign="right" pr={2}>
                    {t('cars.withCar')}
                  </Box>
                </Grid>
              ) : undefined}
            </EntryRow>
            {filteredAmountFields.map((field) => (
              <AmountField
                highlighted={field.highlighted}
                title={t(field.title)}
                key={field.property}
                value={hasProp(state.calculated, field.property) ? (state.calculated[field.property] as number) : 0}
                comparisonValue={
                  details === 2 &&
                  state.values.carLeasingProvider &&
                  state.calculated.originalSalary &&
                  hasProp(state.calculated.originalSalary, field.property)
                    ? (state.calculated.originalSalary[field.property] as number)
                    : undefined
                }
                description={field.description && (t(field.description) as string)}
              />
            ))}
            {state.values.carLeasingProvider > 0 && state.calculated.car && (
              <>
                <AmountField title={t('cars.carNetDifference')} value={state.calculated.car.netDifference} />
                <AmountField title={t('cars.totalPayIncrease')} value={state.calculated.car.totalPayIncrease} />
              </>
            )}
            <Summary label="hr.accountPayout" value={state.calculated.racun} />
          </Segment>

          {/* <Summary label="hr.accountPayout" value={state?.calculated.racun} /> */}
          <Footer label="hr.footer" isOffer={false} state={state} chart={chart} />
        </ContentLayout>
      </OfferProvider>
    </CurrencyProvider>
  );
};
