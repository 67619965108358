import { HrkToEuro } from '../../../../constants/euro';

export const KOEF_BROJ_DJECE = {
  2023: [0.7, 1.0, 1.4, 1.9, 2.5, 3.2, 4.0, 4.9, 5.9],
  2024: [0.5, 0.7, 1.0, 1.4, 1.9, 2.5, 3.2, 4.0, 4.9],
  2025: [0.5, 0.7, 1.0, 1.4, 1.9, 2.5, 3.2, 4.0, 4.9],
};

export const UNTAXABLE = {
  2023: 4000 / HrkToEuro, // 530,89123366
  2024: 560,
  2025: 600,
};

export const LIMIT_TAX_HIGH = {
  2023: 30000 / HrkToEuro, // 3981,68425244
  2024: 4200,
  2025: 5000,
};

export const LIMIT_DOPRINOS = {
  2023: 61806.11 / HrkToEuro, // 8203,08049638
  2024: 9360,
  2025: 9360,
};

const COUNTIES_2023 = [
  { id: 0, name: 'hr.noSurtax', rate: 0 },
  { id: 1, name: 'Zagreb', rate: 18 },
  { id: 2, name: 'Andrijaševci', rate: 8 },
  { id: 3, name: 'Antunovac', rate: 0 },
  { id: 4, name: 'Babina Greda', rate: 5, special: true },
  { id: 5, name: 'Bale', rate: 1 },
  { id: 6, name: 'Barban', rate: 5 },
  { id: 7, name: 'Bedenica', rate: 3 },
  { id: 8, name: 'Bednja', rate: 10 },
  { id: 9, name: 'Beli Manastir', rate: 1 },
  { id: 10, name: 'Belica', rate: 1 },
  { id: 11, name: 'Belišće', rate: 10 },
  { id: 12, name: 'Benkovac', rate: 5 },
  { id: 13, name: 'Beretinec', rate: 7 },
  { id: 14, name: 'Bilice', rate: 10 },
  { id: 15, name: 'Bilje', rate: 5 },
  { id: 16, name: 'Biograd na Moru', rate: 12 },
  { id: 17, name: 'Biskupija', rate: 4, special: true },
  { id: 18, name: 'Bistra', rate: 10 },
  { id: 19, name: 'Bizovac', rate: 3 },
  { id: 20, name: 'Bjelovar', rate: 0 },
  { id: 21, name: 'Blato', rate: 10 },
  { id: 22, name: 'Bol', rate: 10 },
  { id: 23, name: 'Borovo', rate: 10 },
  { id: 24, name: 'Bošnjaci', rate: 0 },
  { id: 25, name: 'Brckovljani', rate: 0 },
  { id: 26, name: 'Brdovec', rate: 10 },
  { id: 27, name: 'Brela', rate: 5 },
  { id: 28, name: 'Brestovac', rate: 5 },
  { id: 29, name: 'Breznica', rate: 10 },
  { id: 30, name: 'Breznički Hum', rate: 3 },
  { id: 31, name: 'Brinje', rate: 10 },
  { id: 32, name: 'Brodski Stupnik', rate: 10 },
  { id: 33, name: 'Buje', rate: 6 },
  { id: 34, name: 'Bukovlje', rate: 5 },
  { id: 35, name: 'Buzet', rate: 6 },
  { id: 36, name: 'Cerna', rate: 5 },
  { id: 37, name: 'Cernik', rate: 10 },
  { id: 38, name: 'Cerovlje', rate: 2 },
  { id: 39, name: 'Cestica', rate: 10 },
  { id: 40, name: 'Cista Provo', rate: 3 },
  { id: 41, name: 'Civljane', rate: 5, special: true },
  { id: 42, name: 'Crikvenica', rate: 10 },
  { id: 43, name: 'Čabar', rate: 5 },
  { id: 44, name: 'Čaglin', rate: 5 },
  { id: 45, name: 'Čakovec', rate: 0 },
  { id: 46, name: 'Čazma', rate: 10 },
  { id: 47, name: 'Čeminac', rate: 3 },
  { id: 48, name: 'Čepin', rate: 10 },
  { id: 49, name: 'Darda', rate: 5 },
  { id: 50, name: 'Daruvar', rate: 10 },
  { id: 51, name: 'Davor', rate: 10 },
  { id: 52, name: 'Delnice', rate: 5 },
  { id: 53, name: 'Dežanovac', rate: 5, special: true },
  { id: 54, name: 'Dicmo', rate: 8 },
  { id: 55, name: 'Donja Stubica', rate: 10 },
  { id: 56, name: 'Donja Voća', rate: 10 },
  { id: 57, name: 'Donji Andrijevci', rate: 8 },
  { id: 58, name: 'Donji Kukuruzari', rate: 6, special: true },
  { id: 59, name: 'Donji Lapac', rate: 5, special: true },
  { id: 60, name: 'Donji Miholjac', rate: 8 },
  { id: 61, name: 'Dragalić', rate: 10 },
  { id: 62, name: 'Draž', rate: 2 },
  { id: 63, name: 'Drenovci', rate: 5, special: true },
  { id: 64, name: 'Drniš', rate: 5 },
  { id: 65, name: 'Dubrava', rate: 6 },
  { id: 66, name: 'Dubravica', rate: 7 },
  { id: 67, name: 'Dubrovačko primorje', rate: 10 },
  { id: 68, name: 'Dubrovnik', rate: 10 },
  { id: 69, name: 'Duga Resa', rate: 10 },
  { id: 70, name: 'Dugo Selo', rate: 9 },
  { id: 71, name: 'Dugopolje', rate: 8 },
  { id: 72, name: 'Dvor', rate: 5, special: true },
  { id: 73, name: 'Đakovo', rate: 10 },
  { id: 74, name: 'Đurđenovac', rate: 8 },
  { id: 75, name: 'Erdut', rate: 1 },
  { id: 76, name: 'Ervenik', rate: 3 },
  { id: 77, name: 'Farkaševac', rate: 3 },
  { id: 78, name: 'Fažana', rate: 3 },
  { id: 79, name: 'Feričanci', rate: 10 },
  { id: 80, name: 'Fužine', rate: 5 },
  { id: 81, name: 'Garčin', rate: 10 },
  { id: 82, name: 'Garešnica', rate: 10 },
  { id: 83, name: 'Gornji Bogićevci', rate: 5, special: true },
  { id: 84, name: 'Gornji Kneginec', rate: 10 },
  { id: 85, name: 'Gospić', rate: 10 },
  { id: 86, name: 'Gračac', rate: 7 },
  { id: 87, name: 'Gračišće', rate: 5 },
  { id: 88, name: 'Gradec', rate: 5 },
  { id: 89, name: 'Grubišno Polje', rate: 10 },
  { id: 90, name: 'Hrašćina', rate: 5 },
  { id: 91, name: 'Hrvace', rate: 10 },
  { id: 92, name: 'Hrvatska Kostajnica', rate: 6 },
  { id: 93, name: 'Ilok', rate: 10 },
  { id: 94, name: 'Imotski', rate: 12 },
  { id: 95, name: 'Ivanec', rate: 10 },
  { id: 96, name: 'Ivanić Grad', rate: 6 },
  { id: 97, name: 'Ivankovo', rate: 10 },
  { id: 98, name: 'Jagodnjak', rate: 3, special: true },
  { id: 99, name: 'Jakovlje', rate: 5 },
  { id: 100, name: 'Jakšić', rate: 5 },
  { id: 101, name: 'Jastrebarsko', rate: 9 },
  { id: 102, name: 'Jelsa', rate: 10 },
  { id: 103, name: 'Kamanje', rate: 10 },
  { id: 104, name: 'Kanfanar', rate: 5 },
  { id: 105, name: 'Kapela', rate: 8, special: true },
  { id: 106, name: 'Kaptol', rate: 10 },
  { id: 107, name: 'Karlovac', rate: 9 },
  { id: 108, name: 'Karojba', rate: 5 },
  { id: 109, name: 'Kaštela', rate: 12 },
  { id: 110, name: 'Kaštelir-Labinci', rate: 5 },
  { id: 111, name: 'Kijevo', rate: 5 },
  { id: 112, name: 'Kistanje', rate: 3, special: true },
  { id: 113, name: 'Klanjec', rate: 12 },
  { id: 114, name: 'Klenovnik', rate: 7 },
  { id: 115, name: 'Klinča Sela', rate: 10 },
  { id: 116, name: 'Klis', rate: 10 },
  { id: 117, name: 'Kloštar Ivanić', rate: 2 },
  { id: 118, name: 'Kloštar Podravski', rate: 5, special: true },
  { id: 119, name: 'Kneževi Vinogradi', rate: 5 },
  { id: 120, name: 'Knin', rate: 10 },
  { id: 121, name: 'Komiža', rate: 5 },
  { id: 122, name: 'Konavle', rate: 10 },
  { id: 123, name: 'Končanica', rate: 5 },
  { id: 124, name: 'Konjščina', rate: 10 },
  { id: 125, name: 'Korčula', rate: 6 },
  { id: 126, name: 'Kraljevica', rate: 5 },
  { id: 127, name: 'Krašić', rate: 6 },
  { id: 128, name: 'Kravarsko', rate: 5 },
  { id: 129, name: 'Križ', rate: 2 },
  { id: 130, name: 'Križevci', rate: 10 },
  { id: 131, name: 'Krnjak', rate: 8, special: true },
  { id: 132, name: 'Kutina', rate: 12 },
  { id: 133, name: 'Labin', rate: 6 },
  { id: 134, name: 'Lanišće', rate: 5 },
  { id: 135, name: 'Lasinja', rate: 10 },
  { id: 136, name: 'Lastovo', rate: 3 },
  { id: 308, name: 'Lekenik', rate: 10 },
  { id: 137, name: 'Lepoglava', rate: 8 },
  { id: 138, name: 'Lipovljani', rate: 6 },
  { id: 139, name: 'Lišane Ostrovičke', rate: 6 },
  { id: 140, name: 'Ližnjan', rate: 5 },
  { id: 141, name: 'Lokvičići', rate: 7, special: true },
  { id: 142, name: 'Lovas', rate: 5 },
  { id: 143, name: 'Lovinac', rate: 8 },
  { id: 144, name: 'Ludbreg', rate: 12 },
  { id: 145, name: 'Luka', rate: 5 },
  { id: 146, name: 'Lukač', rate: 8 },
  { id: 147, name: 'Lumbarda', rate: 5 },
  { id: 148, name: 'Lupoglav', rate: 1 },
  { id: 149, name: 'Ljubešćica', rate: 10 },
  { id: 150, name: 'Magadenovac', rate: 2 },
  { id: 151, name: 'Majur', rate: 5 },
  { id: 152, name: 'Makarska', rate: 10 },
  { id: 153, name: 'Mali Bukovec', rate: 5 },
  { id: 154, name: 'Marčana', rate: 5 },
  { id: 155, name: 'Marija Gorica', rate: 10 },
  { id: 156, name: 'Marijanci', rate: 5 },
  { id: 157, name: 'Markušica', rate: 5, special: true },
  { id: 158, name: 'Martijanec', rate: 5 },
  { id: 159, name: 'Maruševec', rate: 5 },
  { id: 160, name: 'Matulji', rate: 10 },
  { id: 161, name: 'Medulin', rate: 5 },
  { id: 162, name: 'Metković', rate: 10 },
  { id: 163, name: 'Milna', rate: 3 },
  { id: 164, name: 'Mljet', rate: 10 },
  { id: 165, name: 'Motovun', rate: 1 },
  { id: 166, name: 'Mrkopalj', rate: 5 },
  { id: 167, name: 'Muć', rate: 7 },
  { id: 168, name: 'Murter', rate: 8 },
  { id: 169, name: 'Našice', rate: 8 },
  { id: 170, name: 'Negoslavci', rate: 5 },
  { id: 171, name: 'Nova Gradiška', rate: 12 },
  { id: 172, name: 'Nova Kapela', rate: 10 },
  { id: 173, name: 'Novi Marof', rate: 10 },
  { id: 174, name: 'Novi Vinodolski', rate: 7 },
  { id: 175, name: 'Novska', rate: 8 },
  { id: 176, name: 'Nuštar', rate: 6 },
  { id: 177, name: 'Ogulin', rate: 10 },
  { id: 178, name: 'Okučani', rate: 10, special: true },
  { id: 179, name: 'Omiš', rate: 8 },
  { id: 180, name: 'Opatija', rate: 0 },
  { id: 181, name: 'Opuzen', rate: 10 },
  { id: 182, name: 'Orle', rate: 5 },
  { id: 183, name: 'Osijek', rate: 13 },
  { id: 184, name: 'Otočac', rate: 7 },
  { id: 185, name: 'Otok (Sinj)', rate: 10 },
  { id: 186, name: 'Otok (Vinkovci)', rate: 5 },
  { id: 187, name: 'Ozalj', rate: 12 },
  { id: 188, name: 'Pakrac', rate: 7 },
  { id: 189, name: 'Pazin', rate: 9 },
  { id: 190, name: 'Perušić', rate: 2 },
  { id: 191, name: 'Petlovac', rate: 5 },
  { id: 192, name: 'Petrijanec', rate: 5 },
  { id: 193, name: 'Petrijevci', rate: 5 },
  { id: 194, name: 'Petrinja', rate: 10 },
  { id: 195, name: 'Pićan', rate: 0 },
  { id: 196, name: 'Pirovac', rate: 6 },
  { id: 197, name: 'Pisarovina', rate: 3 },
  { id: 198, name: 'Pleternica', rate: 0 },
  { id: 199, name: 'Plitvička jezera', rate: 5 },
  { id: 200, name: 'Podbablje', rate: 6 },
  { id: 201, name: 'Podcrkavlje', rate: 5 },
  { id: 202, name: 'Podgora', rate: 10 },
  { id: 203, name: 'Podgorač', rate: 5, special: true },
  { id: 204, name: 'Podstrana', rate: 8 },
  { id: 205, name: 'Pokupsko', rate: 5 },
  { id: 206, name: 'Polača', rate: 10 },
  { id: 207, name: 'Popovac', rate: 3 },
  { id: 208, name: 'Popovača', rate: 6 },
  { id: 209, name: 'Požega', rate: 7 },
  { id: 210, name: 'Pregrada', rate: 10 },
  { id: 211, name: 'Preseka', rate: 3 },
  { id: 212, name: 'Primošten', rate: 10 },
  { id: 213, name: 'Proložac', rate: 5 },
  { id: 214, name: 'Promina', rate: 5 },
  { id: 215, name: 'Pučišća', rate: 5 },
  { id: 216, name: 'Pula', rate: 12 },
  { id: 217, name: 'Pušća', rate: 10 },
  { id: 218, name: 'Rakovec', rate: 3 },
  { id: 219, name: 'Rakovica', rate: 8 },
  { id: 220, name: 'Raša', rate: 6 },
  { id: 221, name: 'Ravna Gora', rate: 7.5 },
  { id: 222, name: 'Rešetari', rate: 10 },
  { id: 223, name: 'Ribnik', rate: 5 },
  { id: 224, name: 'Rijeka', rate: 13 },
  { id: 225, name: 'Rovinj', rate: 6 },
  { id: 226, name: 'Rugvica', rate: 6 },
  { id: 227, name: 'Runovići', rate: 6 },
  { id: 228, name: 'Ružić', rate: 5 },
  { id: 229, name: 'Senj', rate: 10 },
  { id: 230, name: 'Sibinj', rate: 10 },
  { id: 231, name: 'Sinj', rate: 8 },
  { id: 232, name: 'Sisak', rate: 10 },
  { id: 233, name: 'Skradin', rate: 5 },
  { id: 234, name: 'Slatina', rate: 6 },
  { id: 235, name: 'Slavonski Brod', rate: 6 },
  { id: 236, name: 'Slunj', rate: 5 },
  { id: 237, name: 'Solin', rate: 10 },
  { id: 238, name: 'Split', rate: 15 },
  { id: 239, name: 'Sračinec', rate: 5 },
  { id: 240, name: 'Stara Gradiška', rate: 5 },
  { id: 241, name: 'Stari Mikanovci', rate: 10 },
  { id: 242, name: 'Staro Petrovo Selo', rate: 10, special: true },
  { id: 243, name: 'Stupnik', rate: 6 },
  { id: 244, name: 'Sutivan', rate: 10 },
  { id: 245, name: 'Sveta Nedjelja (Grad)', rate: 0 },
  { id: 246, name: 'Sveta Nedjelja (Općina)', rate: 6 },
  { id: 247, name: 'Sveti Đurđ', rate: 5 },
  { id: 248, name: 'Sveti Ilija', rate: 10 },
  { id: 249, name: 'Sveti Ivan Zelina', rate: 12 },
  { id: 250, name: 'Sveti Lovreč', rate: 5 },
  { id: 251, name: 'Sveti Petar u Šumi', rate: 7 },
  { id: 252, name: 'Svetvinčenat', rate: 5 },
  { id: 253, name: 'Šibenik', rate: 10 },
  { id: 254, name: 'Škabrnja', rate: 10 },
  { id: 255, name: 'Špišić Bukovica', rate: 9, special: true },
  { id: 256, name: 'Štitar', rate: 10, special: true },
  { id: 257, name: 'Tinjan', rate: 5 },
  { id: 258, name: 'Tisno', rate: 9 },
  { id: 259, name: 'Tordinci', rate: 5 },
  { id: 260, name: 'Tribunj', rate: 6 },
  { id: 261, name: 'Trnovec Bartolovečki', rate: 7 },
  { id: 262, name: 'Trogir', rate: 8 },
  { id: 263, name: 'Trpanj', rate: 10 },
  { id: 264, name: 'Tučepi', rate: 10 },
  { id: 265, name: 'Udbina', rate: 5 },
  { id: 266, name: 'Umag', rate: 6 },
  { id: 267, name: 'Unešić', rate: 5 },
  { id: 268, name: 'Valpovo', rate: 8 },
  { id: 269, name: 'Varaždin', rate: 7.5 },
  { id: 270, name: 'Varaždinske Toplice', rate: 10 },
  { id: 271, name: 'Vela Luka', rate: 9 },
  { id: 272, name: 'Velika Gorica', rate: 12 },
  { id: 273, name: 'Velika Kopanica', rate: 7 },
  { id: 274, name: 'Veliki Bukovec', rate: 5 },
  { id: 275, name: 'Veliko Trgovišće', rate: 7.5 },
  { id: 276, name: 'Vidovec', rate: 7 },
  { id: 277, name: 'Vinica', rate: 5 },
  { id: 278, name: 'Vinkovci', rate: 10 },
  { id: 279, name: 'Virovitica', rate: 10 },
  { id: 280, name: 'Vis', rate: 3 },
  { id: 281, name: 'Visoko', rate: 10 },
  { id: 282, name: 'Višnjan', rate: 5 },
  { id: 283, name: 'Vižinada', rate: 3 },
  { id: 284, name: 'Vladislavci', rate: 10 },
  { id: 285, name: 'Vodice', rate: 0 },
  { id: 286, name: 'Vodnjan', rate: 7.5 },
  { id: 287, name: 'Vojnić', rate: 7.5, special: true },
  { id: 288, name: 'Vrbanja', rate: 5 },
  { id: 289, name: 'Vrbje', rate: 3, special: true },
  { id: 290, name: 'Vrbovec', rate: 12 },
  { id: 291, name: 'Vrbovsko', rate: 6 },
  { id: 292, name: 'Vrgorac', rate: 10 },
  { id: 293, name: 'Vrhovine', rate: 5 },
  { id: 294, name: 'Vrlika', rate: 7 },
  { id: 295, name: 'Vukovar', rate: 0, special: true },
  { id: 296, name: 'Zadar', rate: 12 },
  { id: 297, name: 'Zadvarje', rate: 1 },
  { id: 298, name: 'Zagvozd', rate: 6 },
  { id: 299, name: 'Zaprešić', rate: 12 },
  { id: 300, name: 'Zlatar Bistrica', rate: 5 },
  { id: 301, name: 'Zlatar', rate: 10 },
  { id: 302, name: 'Zmijavci', rate: 8 },
  { id: 303, name: 'Žakanje', rate: 5 },
  { id: 304, name: 'Žminj', rate: 5 },
  { id: 305, name: 'Žumberak', rate: 3 },
  { id: 306, name: 'Župa Dubrovačka', rate: 10 },
  { id: 307, name: 'Županja', rate: 9 },
];

// https://www.isplate.info/porez-na-dohodak-porezne-stope.aspx
// copy(Array.from(document.querySelectorAll('article.border-bottom')).map((el) => ({
//   name: el.querySelector('.h2').innerText,
//   rateLow: parseFloat(el.querySelectorAll('.display-2')[0].innerText.replace(',', '.')),
//   rateHigh: parseFloat(el.querySelectorAll('.display-2')[1].innerText.replace(',', '.'))
// })))

const COUNTIES_2024 = [
  { id: 0, name: 'hr.basicTax', rateLow: 20, rateHigh: 30 },
  { id: 1, name: 'Zagreb', rateLow: 23.6, rateHigh: 35.4 },
  { id: 183, name: 'Osijek', rateLow: 20, rateHigh: 30 }, // Not official
  { id: 32, name: 'Brodski Stupnik', rateLow: 20, rateHigh: 30 },
  { id: 114, name: 'Klenovnik', rateLow: 22, rateHigh: 30 },
  { id: 389, name: 'Nin', rateLow: 20, rateHigh: 30 }, //
  { id: 388, name: 'Novigrad-Cittanova', rateLow: 20, rateHigh: 33 }, //
  { id: 202, name: 'Podgora', rateLow: 20, rateHigh: 33 },
  { id: 288, name: 'Vrbanja', rateLow: 20, rateHigh: 30 },
  { id: 2, name: 'Andrijaševci', rateLow: 20, rateHigh: 30 },
  { id: 5, name: 'Bale-Valle', rateLow: 20, rateHigh: 30 },
  { id: 6, name: 'Barban', rateLow: 20, rateHigh: 30 },
  { id: 372, name: 'Bebrina', rateLow: 20, rateHigh: 30 }, //
  { id: 8, name: 'Bednja', rateLow: 20, rateHigh: 30 },
  { id: 373, name: 'Brtonigla-Verteneglio', rateLow: 20, rateHigh: 30 }, //
  { id: 44, name: 'Čaglin', rateLow: 20, rateHigh: 30 },
  { id: 47, name: 'Čeminac', rateLow: 20, rateHigh: 25 },
  { id: 48, name: 'Čepin', rateLow: 22, rateHigh: 33 },
  { id: 59, name: 'Donji Lapac', rateLow: 20, rateHigh: 30 },
  { id: 374, name: 'Dugi Rat', rateLow: 20, rateHigh: 30 }, //
  { id: 72, name: 'Dvor', rateLow: 20, rateHigh: 30 },
  { id: 80, name: 'Fužine', rateLow: 20, rateHigh: 30 },
  { id: 375, name: 'Gradina', rateLow: 20, rateHigh: 30 }, //
  { id: 376, name: 'Gunja', rateLow: 20, rateHigh: 30 }, //
  { id: 377, name: 'Hlebine', rateLow: 20, rateHigh: 30 }, //
  { id: 100, name: 'Jakšić', rateLow: 20, rateHigh: 30 },
  { id: 102, name: 'Jelsa', rateLow: 22, rateHigh: 33 },
  { id: 103, name: 'Kamanje', rateLow: 22, rateHigh: 33 },
  { id: 108, name: 'Karojba', rateLow: 20, rateHigh: 30 },
  { id: 131, name: 'Krnjak', rateLow: 20, rateHigh: 30 },
  { id: 140, name: 'Ližnjan-Lisignano', rateLow: 20, rateHigh: 30 },
  { id: 141, name: 'Lokvičići', rateLow: 20, rateHigh: 30 },
  { id: 145, name: 'Luka', rateLow: 20, rateHigh: 30 },
  { id: 147, name: 'Lumbarda', rateLow: 20, rateHigh: 30 },
  { id: 156, name: 'Marijanci', rateLow: 20, rateHigh: 30 },
  { id: 161, name: 'Medulin', rateLow: 20, rateHigh: 30 },
  { id: 172, name: 'Nova Kapela', rateLow: 20, rateHigh: 30 },
  { id: 378, name: 'Novigrad', rateLow: 20, rateHigh: 30 }, //
  { id: 379, name: 'Orebić', rateLow: 20, rateHigh: 30 }, //
  { id: 182, name: 'Orle', rateLow: 20, rateHigh: 30 },
  { id: 380, name: 'Peteranec', rateLow: 20, rateHigh: 30 }, //
  { id: 199, name: 'Plitvička Jezera', rateLow: 21, rateHigh: 33 },
  { id: 381, name: 'Posedarje', rateLow: 18, rateHigh: 32 }, //
  { id: 382, name: 'Privlaka (Zadar)', rateLow: 20, rateHigh: 30 }, //
  { id: 383, name: 'Radoboj', rateLow: 22, rateHigh: 32 }, //
  { id: 223, name: 'Ribnik', rateLow: 22, rateHigh: 33 },
  { id: 240, name: 'Stara Gradiška', rateLow: 20, rateHigh: 30 },
  { id: 384, name: 'Ston', rateLow: 20, rateHigh: 33 }, //
  { id: 385, name: 'Suhopolje', rateLow: 20, rateHigh: 30 }, //
  { id: 244, name: 'Sutivan', rateLow: 22, rateHigh: 33 },
  { id: 248, name: 'Sveti Ilija', rateLow: 20, rateHigh: 30 },
  { id: 386, name: 'Sveti Križ Začretje', rateLow: 20, rateHigh: 30 }, //
  { id: 251, name: 'Sveti Petar u Šumi', rateLow: 20, rateHigh: 30 },
  { id: 387, name: 'Šodolovci', rateLow: 20, rateHigh: 30 }, //
  { id: 259, name: 'Tordinci', rateLow: 20, rateHigh: 30 },
  { id: 264, name: 'Tučepi', rateLow: 20, rateHigh: 33 },
  { id: 287, name: 'Vojnić', rateLow: 21, rateHigh: 31 },
  { id: 94, name: 'Imotski', rateLow: 20, rateHigh: 25 },
  { id: 121, name: 'Komiža', rateLow: 20, rateHigh: 30 },
  { id: 130, name: 'Križevci', rateLow: 20, rateHigh: 30 },
  { id: 152, name: 'Makarska', rateLow: 20, rateHigh: 33 },
  { id: 173, name: 'Novi Marof', rateLow: 20, rateHigh: 30 },
  { id: 209, name: 'Požega', rateLow: 20, rateHigh: 30 },
  { id: 194, name: 'Petrinja', rateLow: 20, rateHigh: 30 },
  { id: 365, name: 'Čađavica', rateLow: 20, rateHigh: 25 }, //
  { id: 366, name: 'Draganić', rateLow: 20, rateHigh: 33 }, //
  { id: 69, name: 'Duga Resa', rateLow: 22, rateHigh: 33 },
  { id: 83, name: 'Gornji Bogićevci', rateLow: 22, rateHigh: 30 },
  { id: 85, name: 'Gospić', rateLow: 22.4, rateHigh: 33.6 },
  { id: 368, name: 'Klakar', rateLow: 20, rateHigh: 30 }, //
  { id: 367, name: 'Milna', rateLow: 21, rateHigh: 33 }, //
  { id: 166, name: 'Mrkopalj', rateLow: 20, rateHigh: 30 },
  { id: 177, name: 'Ogulin', rateLow: 20, rateHigh: 33 },
  { id: 179, name: 'Omiš', rateLow: 21.6, rateHigh: 32.4 },
  { id: 216, name: 'Pula-Pola', rateLow: 21, rateHigh: 31 },
  { id: 241, name: 'Stari Mikanovci', rateLow: 20, rateHigh: 30 },
  { id: 249, name: 'Sveti Ivan Zelina', rateLow: 20, rateHigh: 30 },
  { id: 250, name: 'Sveti Lovreč', rateLow: 20, rateHigh: 33 },
  { id: 265, name: 'Udbina', rateLow: 20, rateHigh: 30 },
  { id: 292, name: 'Vrgorac', rateLow: 22, rateHigh: 25 },
  { id: 369, name: 'Vuka', rateLow: 20, rateHigh: 30 }, //
  { id: 370, name: 'Zdenci', rateLow: 20, rateHigh: 30 }, //
  { id: 303, name: 'Žakanje', rateLow: 22, rateHigh: 33 },
  { id: 3, name: 'Antunovac', rateLow: 20, rateHigh: 30 },
  { id: 13, name: 'Beretinec', rateLow: 20, rateHigh: 30 },
  { id: 17, name: 'Biskupija', rateLow: 20, rateHigh: 30 },
  { id: 22, name: 'Bol', rateLow: 20, rateHigh: 30 },
  { id: 49, name: 'Darda', rateLow: 20, rateHigh: 30 },
  { id: 63, name: 'Drenovci', rateLow: 20, rateHigh: 30 },
  { id: 70, name: 'Dugo Selo', rateLow: 20, rateHigh: 30 },
  { id: 75, name: 'Erdut', rateLow: 20, rateHigh: 30 },
  { id: 91, name: 'Hrvace', rateLow: 20, rateHigh: 30 },
  { id: 116, name: 'Klis', rateLow: 22, rateHigh: 33 },
  { id: 134, name: 'Lanišće', rateLow: 21, rateHigh: 30 },
  { id: 136, name: 'Lastovo', rateLow: 22, rateHigh: 33 },
  { id: 153, name: 'Mali Bukovec', rateLow: 20, rateHigh: 30 },
  { id: 160, name: 'Matulji', rateLow: 21.8, rateHigh: 32.8 },
  { id: 359, name: 'Mihovljan', rateLow: 20, rateHigh: 30 }, //
  { id: 201, name: 'Podcrkavlje', rateLow: 20, rateHigh: 30 },
  { id: 205, name: 'Pokupsko', rateLow: 21, rateHigh: 31.5 },
  { id: 360, name: 'Poličnik', rateLow: 20, rateHigh: 30 }, //
  { id: 210, name: 'Pregrada', rateLow: 22.3, rateHigh: 33.6 },
  { id: 220, name: 'Raša', rateLow: 20, rateHigh: 30 },
  { id: 361, name: 'Rovišće', rateLow: 20, rateHigh: 30 }, //
  { id: 228, name: 'Ružić', rateLow: 20, rateHigh: 30 },
  { id: 362, name: 'Stari Jankovci', rateLow: 15, rateHigh: 25 }, //
  { id: 262, name: 'Trogir', rateLow: 21.6, rateHigh: 32.4 },
  { id: 363, name: 'Viškovo', rateLow: 20, rateHigh: 30 }, //
  { id: 364, name: 'Vođinci', rateLow: 20, rateHigh: 30 }, //
  { id: 304, name: 'Žminj', rateLow: 21.3, rateHigh: 31.3 },
  { id: 357, name: 'Kastav', rateLow: 20, rateHigh: 33.6 }, //
  { id: 358, name: 'Oroslavje', rateLow: 18, rateHigh: 30 }, //
  { id: 184, name: 'Otočac', rateLow: 20, rateHigh: 30 },
  { id: 224, name: 'Rijeka', rateLow: 22.4, rateHigh: 33.6 },
  { id: 231, name: 'Sinj', rateLow: 18, rateHigh: 30 },
  { id: 232, name: 'Sisak', rateLow: 23, rateHigh: 34 },
  { id: 236, name: 'Slunj', rateLow: 20, rateHigh: 30 },
  { id: 279, name: 'Virovitica', rateLow: 20, rateHigh: 30 },
  { id: 307, name: 'Županja', rateLow: 21.8, rateHigh: 32.7 },
  { id: 4, name: 'Babina Greda', rateLow: 21, rateHigh: 31.5, special: true },
  { id: 350, name: 'Baška Voda', rateLow: 17, rateHigh: 27 }, //
  { id: 7, name: 'Bedenica', rateLow: 21, rateHigh: 30 },
  { id: 15, name: 'Bilje', rateLow: 20, rateHigh: 30 },
  { id: 23, name: 'Borovo', rateLow: 20, rateHigh: 30 },
  { id: 56, name: 'Donja Voća', rateLow: 22, rateHigh: 33 },
  { id: 351, name: 'Drenje', rateLow: 20, rateHigh: 30 }, //
  { id: 64, name: 'Drniš', rateLow: 20, rateHigh: 25 },
  { id: 74, name: 'Đurđenovac', rateLow: 20, rateHigh: 30 },
  { id: 352, name: 'Grožnjan-Grisignana', rateLow: 20, rateHigh: 30 }, //
  { id: 353, name: 'Hercegovac', rateLow: 20, rateHigh: 30 }, //
  { id: 123, name: 'Končanica', rateLow: 22, rateHigh: 30 },
  { id: 139, name: 'Lišane Ostrovičke', rateLow: 22, rateHigh: 33 },
  { id: 158, name: 'Martijanec', rateLow: 22, rateHigh: 33 },
  { id: 354, name: 'Semeljci', rateLow: 20, rateHigh: 30 }, //
  { id: 230, name: 'Sibinj', rateLow: 22, rateHigh: 33 },
  { id: 355, name: 'Veliki Grđevac', rateLow: 20, rateHigh: 30 }, //
  { id: 283, name: 'Vižinada-Visinada', rateLow: 20, rateHigh: 30 },
  { id: 305, name: 'Žumberak', rateLow: 20, rateHigh: 30 },
  { id: 306, name: 'Župa Dubrovačka', rateLow: 20, rateHigh: 30 },
  { id: 9, name: 'Beli Manastir', rateLow: 20, rateHigh: 25 },
  { id: 43, name: 'Čabar', rateLow: 22, rateHigh: 30 },
  { id: 171, name: 'Nova Gradiška', rateLow: 22, rateHigh: 33 },
  { id: 238, name: 'Split', rateLow: 21.5, rateHigh: 32.25 },
  { id: 356, name: 'Sveta Nedelja (Samobor)', rateLow: 18, rateHigh: 28 }, //
  { id: 270, name: 'Varaždinske Toplice', rateLow: 22, rateHigh: 33.6 },
  { id: 25, name: 'Brckovljani', rateLow: 20, rateHigh: 30 },
  { id: 31, name: 'Brinje', rateLow: 22, rateHigh: 33 },
  { id: 36, name: 'Cerna', rateLow: 20, rateHigh: 30 },
  { id: 53, name: 'Dežanovac', rateLow: 22, rateHigh: 30, special: true },
  { id: 61, name: 'Dragalić', rateLow: 22, rateHigh: 33 },
  { id: 104, name: 'Kanfanar', rateLow: 20, rateHigh: 30 },
  { id: 105, name: 'Kapela', rateLow: 15, rateHigh: 25, special: true },
  { id: 320, name: 'Krapina', rateLow: 20, rateHigh: 30 }, //
  { id: 135, name: 'Lasinja', rateLow: 22, rateHigh: 33 },
  { id: 321, name: 'Lobor', rateLow: 20, rateHigh: 30 }, //
  { id: 148, name: 'Lupoglav', rateLow: 21, rateHigh: 32 },
  { id: 155, name: 'Marija Gorica', rateLow: 20, rateHigh: 30 },
  { id: 157, name: 'Markušica', rateLow: 20, rateHigh: 30, special: true },
  { id: 159, name: 'Maruševec', rateLow: 21, rateHigh: 31 },
  { id: 170, name: 'Negoslavci', rateLow: 20, rateHigh: 30 },
  { id: 322, name: 'Oprtalj-Portole', rateLow: 20, rateHigh: 30 }, //
  { id: 190, name: 'Perušić', rateLow: 20, rateHigh: 30 },
  { id: 323, name: 'Punat', rateLow: 15, rateHigh: 30 }, //
  { id: 324, name: 'Sirač', rateLow: 20, rateHigh: 30 }, //
  { id: 325, name: 'Sopje', rateLow: 20, rateHigh: 30 }, //
  { id: 239, name: 'Sračinec', rateLow: 22, rateHigh: 30 },
  { id: 326, name: 'Starigrad', rateLow: 20, rateHigh: 30 }, //
  { id: 260, name: 'Tribunj', rateLow: 20, rateHigh: 30 },
  { id: 261, name: 'Trnovec Bartolovečki', rateLow: 22, rateHigh: 32 },
  { id: 327, name: 'Velika', rateLow: 20, rateHigh: 30 }, //
  { id: 281, name: 'Visoko', rateLow: 20, rateHigh: 30 },
  { id: 284, name: 'Vladislavci', rateLow: 20, rateHigh: 25 },
  { id: 289, name: 'Vrbje', rateLow: 22, rateHigh: 33, special: true },
  { id: 328, name: 'Vrsi', rateLow: 15, rateHigh: 30 }, //
  { id: 33, name: 'Buje-Buie', rateLow: 20, rateHigh: 30 },
  { id: 55, name: 'Donja Stubica', rateLow: 22, rateHigh: 33 },
  { id: 57, name: 'Donji Andrijevci', rateLow: 20, rateHigh: 30 },
  { id: 329, name: 'Glina', rateLow: 20, rateHigh: 30 }, //
  { id: 93, name: 'Ilok', rateLow: 20, rateHigh: 30 },
  { id: 101, name: 'Jastrebarsko', rateLow: 20, rateHigh: 30 },
  { id: 107, name: 'Karlovac', rateLow: 20, rateHigh: 33 },
  { id: 174, name: 'Novi Vinodolski', rateLow: 20, rateHigh: 30 },
  { id: 175, name: 'Novska', rateLow: 20, rateHigh: 30 },
  { id: 187, name: 'Ozalj', rateLow: 20, rateHigh: 30 },
  { id: 330, name: 'Pag', rateLow: 20, rateHigh: 30 }, //
  { id: 188, name: 'Pakrac', rateLow: 20, rateHigh: 30 },
  { id: 331, name: 'Samobor', rateLow: 18, rateHigh: 27 }, //
  { id: 332, name: 'Stari Grad', rateLow: 22, rateHigh: 33 }, //
  { id: 268, name: 'Valpovo', rateLow: 22, rateHigh: 33.6 },
  { id: 269, name: 'Varaždin', rateLow: 22, rateHigh: 33 },
  { id: 286, name: 'Vodnjan-Dignano', rateLow: 22.4, rateHigh: 33.6 },
  { id: 291, name: 'Vrbovsko', rateLow: 22.2, rateHigh: 33.6 },
  { id: 294, name: 'Vrlika', rateLow: 20, rateHigh: 30 },
  { id: 11, name: 'Belišće', rateLow: 21.4, rateHigh: 32.1 },
  { id: 26, name: 'Brdovec', rateLow: 20, rateHigh: 30 },
  { id: 333, name: 'Čačinci', rateLow: 20, rateHigh: 30 }, //
  { id: 334, name: 'Goričan', rateLow: 20, rateHigh: 30 }, //
  { id: 335, name: 'Gradac', rateLow: 20, rateHigh: 33 }, //
  { id: 128, name: 'Kravarsko', rateLow: 20, rateHigh: 30 },
  { id: 336, name: 'Lipik', rateLow: 20, rateHigh: 30 }, //
  { id: 150, name: 'Magadenovac', rateLow: 20, rateHigh: 30 },
  { id: 337, name: 'Okrug', rateLow: 20, rateHigh: 30 }, //
  { id: 192, name: 'Petrijanec', rateLow: 22, rateHigh: 33 },
  { id: 338, name: 'Ploče', rateLow: 20, rateHigh: 30 }, //
  { id: 212, name: 'Primošten', rateLow: 22, rateHigh: 33 },
  { id: 258, name: 'Tisno', rateLow: 20, rateHigh: 30 },
  { id: 273, name: 'Velika Kopanica', rateLow: 20, rateHigh: 30 },
  { id: 293, name: 'Vrhovine', rateLow: 18, rateHigh: 30 },
  { id: 35, name: 'Buzet', rateLow: 22, rateHigh: 33 },
  { id: 50, name: 'Daruvar', rateLow: 20, rateHigh: 30 },
  { id: 95, name: 'Ivanec', rateLow: 22, rateHigh: 33 },
  { id: 120, name: 'Knin', rateLow: 22, rateHigh: 33 },
  { id: 19, name: 'Bizovac', rateLow: 20, rateHigh: 30 },
  { id: 339, name: 'Ernestinovo', rateLow: 20, rateHigh: 30 }, //
  { id: 106, name: 'Kaptol', rateLow: 20, rateHigh: 30 },
  { id: 137, name: 'Lepoglava', rateLow: 21, rateHigh: 31 },
  { id: 146, name: 'Lukač', rateLow: 20, rateHigh: 30 },
  { id: 54, name: 'Dicmo', rateLow: 21.6, rateHigh: 32.4 },
  { id: 89, name: 'Grubišno Polje', rateLow: 20, rateHigh: 30 },
  { id: 115, name: 'Klinča Sela', rateLow: 20, rateHigh: 30 },
  { id: 308, name: 'Lekenik', rateLow: 20, rateHigh: 30 },
  { id: 340, name: 'Supetar', rateLow: 22.4, rateHigh: 33.6 }, //
  { id: 371, name: 'Baška', rateLow: 15, rateHigh: 25 }, //
  { id: 14, name: 'Bilice', rateLow: 20, rateHigh: 30 },
  { id: 20, name: 'Bjelovar', rateLow: 18, rateHigh: 28 },
  { id: 29, name: 'Breznica', rateLow: 22, rateHigh: 33 },
  { id: 342, name: 'Jasenovac', rateLow: 20, rateHigh: 30 }, //
  { id: 144, name: 'Ludbreg', rateLow: 21.6, rateHigh: 33.6 },
  { id: 189, name: 'Pazin', rateLow: 22, rateHigh: 30 },
  { id: 256, name: 'Štitar', rateLow: 20, rateHigh: 33, special: true },
  { id: 343, name: 'Tar-Vabriga-Torre-Abrega', rateLow: 20, rateHigh: 30 }, //
  { id: 274, name: 'Veliki Bukovec', rateLow: 21.6, rateHigh: 33 },
  { id: 111, name: 'Kijevo', rateLow: 20, rateHigh: 30 },
  { id: 164, name: 'Mljet', rateLow: 22, rateHigh: 33 },
  { id: 222, name: 'Rešetari', rateLow: 22, rateHigh: 33 },
  { id: 344, name: 'Veliko Trojstvo', rateLow: 17.5, rateHigh: 27.5 }, //
  { id: 345, name: 'Crnac', rateLow: 20, rateHigh: 30 }, //
  { id: 346, name: 'Molve', rateLow: 15, rateHigh: 25 }, //
  { id: 275, name: 'Veliko Trgovišće', rateLow: 22, rateHigh: 33 }, //
  { id: 277, name: 'Vinica', rateLow: 20, rateHigh: 30 },
  { id: 87, name: 'Gračišće', rateLow: 21, rateHigh: 31 },
  { id: 347, name: 'Stankovci', rateLow: 20, rateHigh: 30 }, //
  { id: 263, name: 'Trpanj', rateLow: 20, rateHigh: 30 },
  { id: 276, name: 'Vidovec', rateLow: 20, rateHigh: 30 },
  { id: 90, name: 'Hrašćina', rateLow: 20, rateHigh: 30 },
  { id: 348, name: 'Severin', rateLow: 18, rateHigh: 28 }, //
  { id: 349, name: 'Pitomača', rateLow: 20, rateHigh: 30 }, //
  { id: 285, name: 'Vodice', rateLow: 20, rateHigh: 30 },
  { id: 10, name: 'Belica', rateLow: 20, rateHigh: 30 },
  { id: 12, name: 'Benkovac', rateLow: 20, rateHigh: 30 },
  { id: 16, name: 'Biograd na Moru', rateLow: 20, rateHigh: 30 },
  { id: 18, name: 'Bistra', rateLow: 20, rateHigh: 30 },
  { id: 21, name: 'Blato', rateLow: 20, rateHigh: 30 },
  { id: 24, name: 'Bošnjaci', rateLow: 20, rateHigh: 30 },
  { id: 27, name: 'Brela', rateLow: 20, rateHigh: 30 },
  { id: 28, name: 'Brestovac', rateLow: 20, rateHigh: 30 },
  { id: 30, name: 'Breznički Hum', rateLow: 20, rateHigh: 30 },
  { id: 34, name: 'Bukovlje', rateLow: 20, rateHigh: 30 },
  { id: 37, name: 'Cernik', rateLow: 20, rateHigh: 30 },
  { id: 38, name: 'Cerovlje', rateLow: 20, rateHigh: 30 },
  { id: 39, name: 'Cestica', rateLow: 20, rateHigh: 30 },
  { id: 40, name: 'Cista Provo', rateLow: 20, rateHigh: 30 },
  { id: 41, name: 'Civljane', rateLow: 20, rateHigh: 30 },
  { id: 42, name: 'Crikvenica', rateLow: 20, rateHigh: 30 },
  { id: 45, name: 'Čakovec', rateLow: 20, rateHigh: 30 },
  { id: 46, name: 'Čazma', rateLow: 20, rateHigh: 30 },
  { id: 51, name: 'Davor', rateLow: 20, rateHigh: 30 },
  { id: 52, name: 'Delnice', rateLow: 20, rateHigh: 30 },
  { id: 58, name: 'Donji Kukuruzari', rateLow: 20, rateHigh: 30 },
  { id: 60, name: 'Donji Miholjac', rateLow: 20, rateHigh: 30 },
  { id: 62, name: 'Draž', rateLow: 20, rateHigh: 30 },
  { id: 65, name: 'Dubrava', rateLow: 20, rateHigh: 30 },
  { id: 66, name: 'Dubravica', rateLow: 20, rateHigh: 30 },
  { id: 67, name: 'Dubrovačko primorje', rateLow: 20, rateHigh: 30 },
  { id: 68, name: 'Dubrovnik', rateLow: 20, rateHigh: 30 },
  { id: 71, name: 'Dugopolje', rateLow: 20, rateHigh: 30 },
  { id: 73, name: 'Đakovo', rateLow: 20, rateHigh: 30 },
  { id: 76, name: 'Ervenik', rateLow: 20, rateHigh: 30 },
  { id: 77, name: 'Farkaševac', rateLow: 20, rateHigh: 30 },
  { id: 78, name: 'Fažana', rateLow: 20, rateHigh: 30 },
  { id: 79, name: 'Feričanci', rateLow: 20, rateHigh: 30 },
  { id: 81, name: 'Garčin', rateLow: 20, rateHigh: 30 },
  { id: 82, name: 'Garešnica', rateLow: 20, rateHigh: 30 },
  { id: 84, name: 'Gornji Kneginec', rateLow: 20, rateHigh: 30 },
  { id: 86, name: 'Gračac', rateLow: 20, rateHigh: 30 },
  { id: 88, name: 'Gradec', rateLow: 20, rateHigh: 30 },
  { id: 92, name: 'Hrvatska Kostajnica', rateLow: 20, rateHigh: 30 },
  { id: 96, name: 'Ivanić Grad', rateLow: 20, rateHigh: 30 },
  { id: 97, name: 'Ivankovo', rateLow: 20, rateHigh: 30 },
  { id: 98, name: 'Jagodnjak', rateLow: 20, rateHigh: 30 },
  { id: 99, name: 'Jakovlje', rateLow: 20, rateHigh: 30 },
  { id: 109, name: 'Kaštela', rateLow: 20, rateHigh: 30 },
  { id: 110, name: 'Kaštelir-Labinci', rateLow: 20, rateHigh: 30 },
  { id: 112, name: 'Kistanje', rateLow: 20, rateHigh: 30 },
  { id: 113, name: 'Klanjec', rateLow: 20, rateHigh: 30 },
  { id: 117, name: 'Kloštar Ivanić', rateLow: 20, rateHigh: 30 },
  { id: 118, name: 'Kloštar Podravski', rateLow: 20, rateHigh: 30 },
  { id: 119, name: 'Kneževi Vinogradi', rateLow: 20, rateHigh: 30 },
  { id: 122, name: 'Konavle', rateLow: 20, rateHigh: 30 },
  { id: 124, name: 'Konjščina', rateLow: 20, rateHigh: 30 },
  { id: 125, name: 'Korčula', rateLow: 20, rateHigh: 30 },
  { id: 126, name: 'Kraljevica', rateLow: 20, rateHigh: 30 },
  { id: 127, name: 'Krašić', rateLow: 20, rateHigh: 30 },
  { id: 129, name: 'Križ', rateLow: 20, rateHigh: 30 },
  { id: 132, name: 'Kutina', rateLow: 20, rateHigh: 30 },
  { id: 133, name: 'Labin', rateLow: 20, rateHigh: 30 },
  { id: 138, name: 'Lipovljani', rateLow: 20, rateHigh: 30 },
  { id: 142, name: 'Lovas', rateLow: 20, rateHigh: 30 },
  { id: 143, name: 'Lovinac', rateLow: 20, rateHigh: 30 },
  { id: 149, name: 'Ljubešćica', rateLow: 20, rateHigh: 30 },
  { id: 151, name: 'Majur', rateLow: 20, rateHigh: 30 },
  { id: 154, name: 'Marčana', rateLow: 20, rateHigh: 30 },
  { id: 162, name: 'Metković', rateLow: 20, rateHigh: 30 },
  { id: 163, name: 'Milna', rateLow: 21, rateHigh: 33 },
  { id: 165, name: 'Motovun', rateLow: 20, rateHigh: 30 },
  { id: 167, name: 'Muć', rateLow: 20, rateHigh: 30 },
  { id: 168, name: 'Murter', rateLow: 20, rateHigh: 30 },
  { id: 169, name: 'Našice', rateLow: 20, rateHigh: 30 },
  { id: 176, name: 'Nuštar', rateLow: 20, rateHigh: 30 },
  { id: 178, name: 'Okučani', rateLow: 20, rateHigh: 30 },
  { id: 180, name: 'Opatija', rateLow: 20, rateHigh: 30 },
  { id: 181, name: 'Opuzen', rateLow: 20, rateHigh: 30 },
  { id: 185, name: 'Otok (Sinj)', rateLow: 20, rateHigh: 30 },
  { id: 186, name: 'Otok (Vinkovci)', rateLow: 20, rateHigh: 30 },
  { id: 191, name: 'Petlovac', rateLow: 20, rateHigh: 30 },
  { id: 193, name: 'Petrijevci', rateLow: 20, rateHigh: 30 },
  { id: 195, name: 'Pićan', rateLow: 20, rateHigh: 30 },
  { id: 196, name: 'Pirovac', rateLow: 20, rateHigh: 30 },
  { id: 197, name: 'Pisarovina', rateLow: 20, rateHigh: 30 },
  { id: 198, name: 'Pleternica', rateLow: 20, rateHigh: 30 },
  { id: 200, name: 'Podbablje', rateLow: 20, rateHigh: 30 },
  { id: 203, name: 'Podgorač', rateLow: 20, rateHigh: 30 },
  { id: 204, name: 'Podstrana', rateLow: 20, rateHigh: 30 },
  { id: 206, name: 'Polača', rateLow: 20, rateHigh: 30 },
  { id: 207, name: 'Popovac', rateLow: 20, rateHigh: 30 },
  { id: 208, name: 'Popovača', rateLow: 20, rateHigh: 30 },
  { id: 211, name: 'Preseka', rateLow: 20, rateHigh: 30 },
  { id: 213, name: 'Proložac', rateLow: 20, rateHigh: 30 },
  { id: 214, name: 'Promina', rateLow: 20, rateHigh: 30 },
  { id: 215, name: 'Pučišća', rateLow: 20, rateHigh: 30 },
  { id: 217, name: 'Pušća', rateLow: 20, rateHigh: 30 },
  { id: 218, name: 'Rakovec', rateLow: 20, rateHigh: 30 },
  { id: 219, name: 'Rakovica', rateLow: 20, rateHigh: 30 },
  { id: 221, name: 'Ravna Gora', rateLow: 20, rateHigh: 30 },
  { id: 225, name: 'Rovinj', rateLow: 20, rateHigh: 30 },
  { id: 226, name: 'Rugvica', rateLow: 20, rateHigh: 30 },
  { id: 227, name: 'Runovići', rateLow: 20, rateHigh: 30 },
  { id: 229, name: 'Senj', rateLow: 20, rateHigh: 30 },
  { id: 233, name: 'Skradin', rateLow: 20, rateHigh: 30 },
  { id: 234, name: 'Slatina', rateLow: 20, rateHigh: 30 },
  { id: 235, name: 'Slavonski Brod', rateLow: 20, rateHigh: 30 },
  { id: 237, name: 'Solin', rateLow: 20, rateHigh: 30 },
  { id: 242, name: 'Staro Petrovo Selo', rateLow: 20, rateHigh: 30 },
  { id: 243, name: 'Stupnik', rateLow: 20, rateHigh: 30 },
  { id: 247, name: 'Sveti Đurđ', rateLow: 20, rateHigh: 5 },
  { id: 252, name: 'Svetvinčenat', rateLow: 20, rateHigh: 5 },
  { id: 253, name: 'Šibenik', rateLow: 20, rateHigh: 10 },
  { id: 254, name: 'Škabrnja', rateLow: 20, rateHigh: 10 },
  { id: 255, name: 'Špišić Bukovica', rateLow: 20, rateHigh: 9 },
  { id: 257, name: 'Tinjan', rateLow: 20, rateHigh: 5 },
  { id: 266, name: 'Umag', rateLow: 20, rateHigh: 30 },
  { id: 267, name: 'Unešić', rateLow: 20, rateHigh: 30 },
  { id: 271, name: 'Vela Luka', rateLow: 20, rateHigh: 30 },
  { id: 272, name: 'Velika Gorica', rateLow: 20, rateHigh: 30 },
  { id: 278, name: 'Vinkovci', rateLow: 20, rateHigh: 30 },
  { id: 280, name: 'Vis', rateLow: 20, rateHigh: 30 },
  { id: 282, name: 'Višnjan', rateLow: 20, rateHigh: 30 },
  { id: 290, name: 'Vrbovec', rateLow: 20, rateHigh: 30 },
  { id: 295, name: 'Vukovar', rateLow: 20, rateHigh: 30 },
  { id: 245, name: 'Sveta Nedjelja (Samobor)', rateLow: 18, rateHigh: 28 },
  { id: 246, name: 'Sveta Nedjelja (Labin)', rateLow: 20, rateHigh: 30 },
  { id: 296, name: 'Zadar', rateLow: 20, rateHigh: 30 },
  { id: 297, name: 'Zadvarje', rateLow: 20, rateHigh: 30 },
  { id: 298, name: 'Zagvozd', rateLow: 20, rateHigh: 30 },
  { id: 299, name: 'Zaprešić', rateLow: 20, rateHigh: 30 },
  { id: 300, name: 'Zlatar Bistrica', rateLow: 20, rateHigh: 30 },
  { id: 301, name: 'Zlatar', rateLow: 20, rateHigh: 30 },
  { id: 302, name: 'Zmijavci', rateLow: 20, rateHigh: 30 },
];

const SORTED_COUNTIES_2024 = [
  ...COUNTIES_2024.slice(0, 2),
  ...COUNTIES_2024.slice(2).sort((a, b) => a.name.localeCompare(b.name)),
];

export const COUNTIES = {
  2023: COUNTIES_2023,
  2024: SORTED_COUNTIES_2024,
  2025: SORTED_COUNTIES_2024, //.map((c) => ({ ...c, rateLow: Math.min(22, c.rateLow) })),
} as const;

export const BASE_ODBITAK = {
  2023: 2500 / HrkToEuro,
  2024: 560,
  2025: 600,
};

export const UZDRZAVANI = {
  2023: 0.7,
  2024: 0.5,
  2025: 0.5,
};

export const INVALIDNOST = {
  2023: 1000 / HrkToEuro,
  2024: 168,
  2025: 168,
};

export const INVALIDNOST_100 = {
  2023: 3750 / HrkToEuro,
  2024: 560,
  2025: 560,
};
